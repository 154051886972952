import Home from "../views/Home.vue";
import Home2 from "../views/Home2.vue";
import TopNav from "@/components/nav/topNav.vue";
import LeftNav from "@/components/nav/leftNav.vue";
import defaultLayout from "@/views/default.vue";

// 懒加载方式，当路由被访问的时候才加载对应组件
const Login = (resolve) => require(["@/views/login"], resolve);

const data = [
  {
    path: "/login",
    type: "login",
    component: Login,
  },
  {
    path: "*",
    component: () => import("@/components/404.vue"),
  },
];

export default data;

import FileSaver from 'file-saver'
import XLSX from 'xlsx'

function exportData(id, name) {
  var xlsxParam = {
    raw: true
  } // 导出的内容只做解析，不进行格式转换
  // tabel的ID
  let wb = XLSX.utils.table_to_book(document.querySelector('#' + id), xlsxParam);
  /* get binary string as output */

  let wbout = XLSX.write(wb, {
    bookType: 'xlsx',
    bookSST: true,
    // cellStyles: true,
    type: 'array'
  });
  try {
    FileSaver.saveAs(new Blob([wbout], {
      type: 'application/octet-stream'
    }), name + '.xlsx');
  } catch (e) {
    if (typeof console !== 'undefined')
      console.log(e, wbout)
  }
  return wbout
}

function exportDataTwo(id, fileName) {

  // // 获取当前table的dom,必须要用clone，如果不可隆的话会导致你现有表中的表格发生改变
  let tableDom = document.querySelector("#" + id).cloneNode(true);
   let tableHeader = tableDom.querySelector('.el-table__header-wrapper');
   let tableBody = tableDom.querySelector('.el-table__body');
  tableHeader.childNodes[0].append(tableBody.childNodes[1]);
  let headerDom = tableHeader.childNodes[0].querySelectorAll('th');
  // 移除左侧checkbox的节点
  // if (headerDom[0].querySelectorAll('.el-checkbox')) {
  //     headerDom[0].remove(); 
  // }
  console.log("---------------------1",headerDom)
  let list =[]
  for (let key in headerDom) {
      if (headerDom[key].innerText === '操作') {
          headerDom[key].remove();
      }else
      if (headerDom[key].innerText === '详细') {
          headerDom[key].remove();
      }
      else
      if (headerDom[key].innerText === '详情') {
          headerDom[key].remove();
      }else{
        list.push(headerDom[key].innerText)

      }
      if (headerDom[key].innerText === '资料管理') {
        headerDom[key].remove();
    }
      console.log("---------------------2"+key,headerDom[key].innerText)
  }
  console.log("---------------------2",list)

  
  // // 清理掉checkbox 和操作的button
  // console.log( tableHeader.childNodes[0].childNodes[0])
  let tableList = tableHeader.childNodes[0].childNodes[2].querySelectorAll('td');
  for (let key = 0; key < tableList.length; key++) {
      if (tableList[key].querySelectorAll('.el-checkbox').length > 0 || tableList[key].querySelectorAll('.el-button').length > 0) {
          tableList[key].remove();
      }
  }

  // // 获取web的节点
  // console.log(tableList)
  console.log(tableHeader)
  let webBook = XLSX.utils.table_to_book(tableHeader);
  // 把当前的book节点写入XLSX中
  let webOut = XLSX.write(webBook, {bookType: 'xlsx', bookSST: true, type: 'array'});
  console.log(webOut)
  try {
      FileSaver.saveAs(new Blob([webOut], {type: 'application/octet-stream'}), fileName + '.xlsx');
  } catch (e) {
      if (typeof console !== 'undefined') console.log(e, webOut)
  }
  return webOut

  // 解决生成重复数据-因为使用l fixed属性 注意你的fixed是left还是right
  var fix = document.querySelector("#" + id + " .el-table__fixed-right");
  var wb;

  // console.log(document.querySelector('#' + id))//<div>
  // 判断要导出的节点中是否有fixed的表格，如果有，转换excel时先将该dom移除，然后append回去
  if (fix) {
    //   /* 从表生成工作簿对象 */
    wb = XLSX.utils.table_to_book(
      document.querySelector('#' + id).removeChild(fix), {
        raw: true
      }
    );
    document.querySelector('#' + id).appendChild(fix);
  } else {
    wb = XLSX.utils.table_to_book(document.querySelector('#' + id), {
      raw: true,
    });
  }

  // /* 获取二进制字符串作为输出 */
  var wbout = XLSX.write(wb, {
    bookType: "xlsx",
    bookSST: true,
    type: "array",
  });
  try {
    FileSaver.saveAs(
      new Blob([wbout], {
        type: "application/octet-stream"
      }),
      // 设置导出文件名称
      fileName + '.xlsx'
    );
  } catch (e) {
    if (typeof console !== "undefined") console.log(e, wbout);
  }
  return wbout;
}


// edit LIU  导入表格解析---开始
function importExcel(sheetData) {

  // 获取行数col和列数row
  let ref = sheetData['!ref']
  let refParse = ref.match(/[a-z]+|[0-9]+/ig)
  let col = refParse[3] - refParse[1] + 1
  let row = refParse[2].charCodeAt() - refParse[0].charCodeAt() + 1

  // 获取单元格合并数据并建立数组索引
  let merges = sheetData['!merges']
  let mergesParses = xlsxMergeParse(merges)
  let mergeIndexs = mergeIndex(mergesParses)
  // console.log(mergeIndexs)

  // 根据表的行数和列数创建一个表，在创建每个单元格时插入数据，数据填充普通单元格直接引用sheetData，
  //合并单元格通过映射下标计算引用sheetData
  let datas = []
  let indexData = [];
  for (let i = 1; i < col + 1; i++) {
    let row = []
    // for (let j = refParse[0].charCodeAt(); j < refParse[2].charCodeAt() + 1 - colNone; j++) {
    for (let j = refParse[0].charCodeAt(); j < refParse[2].charCodeAt() + 1; j++) {
      let letter = String.fromCharCode(j)
      let k = j - 64
      row.push(sheetData[letter + i] ? sheetData[letter + i].w : autoFill([i, k], mergeIndexs,
        mergesParses, sheetData))
      // console.log(sheetData[letter + i])
    }
    datas.push(row)
    // console.log(datas)
    
    if (!sheetData[String.fromCharCode(refParse[0].charCodeAt()) + i]) {
      // 判断此单元格是否属于合并单元格
      let point = [i, refParse[0].charCodeAt() - 64]; 
      let index = mergeIndexs;
      let isNeed = index.indexOf(point.join(','))
      // console.log(isNeed)
      // if (isNeed < 0) return undefined
      if (isNeed < 0) {

      }else {
        // 通过索引获取映射的合并数据三维数组的下标
      let target = index[isNeed + 1]
      let result = target.split('-')[0]
      result = mergesParses[result][0]
      // console.log(result)
      indexData.push(result[0]-2)
      }
    }else{
      indexData.push(i-2)
    }
    
  }
  indexData = indexData.slice(1)
  // console.log(indexData)
  

  for (var i = 1; i < datas.length; i++) {
    var currentData = datas[i];
    for (var j = 0; j < currentData.length; j++) {
      if (currentData[j] == undefined) {
        console.log(currentData)
        // 
        return false
      }
    }
  }
  // 将数据拼接 
  let tabelHead = datas[0];
  let arr = []
  for (var i = 1; i < datas.length; i++) {
    var currentData = datas[i];
    let obj = {}
    for (var j = 0; j < tabelHead.length; j++) {
      var property = tabelHead[j];
      let value = currentData[j]
      obj[property] = value;
    }
    arr.push(obj)
  }
  // console.log(arr)
  var currentData = {};
  currentData.dataArr = arr;
  currentData.indexData = indexData;
  return currentData
}
// 解析单元格合并数据方法
// 解析表格单元格合并数据  把参与合并的单元格全部计算并统计位置信息
function xlsxMergeParse(merges) {
  let arr = []
if(!merges){//undefined没有合并单元格
  return arr
}
  merges.map(v => {
    let {
      s,
      e
    } = v
    let result = []
    let addNum = 1 //使数组下标加一，符合Excel单元格下标
    if (s.c === e.c) {
      for (let i = s.r; i < e.r + 1; i++) {
        result.push([i + addNum, s.c + addNum])
      }
    } else {
      for (let i = s.c; i < e.c + 1; i++) {
        result.push([s.r + addNum, i + addNum])
      }
    }
    arr.push(result)
  })
  // console.log(arr)

  return arr

}

// 单元格合并数据建立索引
// 单元格合并数据为三维数组，为了提升数据处理效率，添加索引（变为一维数组，序列化位置信息）
function mergeIndex(mergesParse) {

  let datas = []

  mergesParse.map((v, i) => {
    let row = []
    v.map((v1, i1) => {
      row.push(v1.join(','))
      row.push([i, i1].join('-'))
    })
    datas.push(row)
  })
  // console.log(datas.flat(Infinity))
  return datas.flat(Infinity)
}

// 根据单元格下标计算应该填充的值
function autoFill(point, index, mergesParses, sheetData) {

  // 判断此单元格是否属于合并单元格
  let isNeed = index.indexOf(point.join(','))
  if (isNeed < 0) return undefined

  // 通过索引获取映射的合并数据三维数组的下标
  let target = index[isNeed + 1]
  let result = target.split('-')[0]
  result = mergesParses[result][0]

  // 返回合并单元格左上单元格数据
  // console.log(result)
  result = sheetData[String.fromCharCode(result[1] + 64) + result[0]].w
  // console.log(String.fromCharCode(result[1] + 64) + result[0])
  // console.log(sheetData[String.fromCharCode(result[1] + 64) + result[0]])
  // console.log(result)

  return result
}

// 导入表格解析--结束

export default {
  exportData,
  exportDataTwo,
  importExcel
}
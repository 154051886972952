/**
 * Created by yqr on 2018/4/13.
 */
const api = process.env.VUE_APP_API_URL;
import Env from './env';
import axios from 'axios'
import {road} from '../road.js'
import routerIndex from '../router/index'

axios.defaults.baseURL = api;
//设置默认请求头
// axios.defaults.headers = {
//     "Content-Type": "application/json",
//     "Accept": "application/json",
//     "appkey": "89a3d5de-c0e2-4458-9539-49a69cb24bc8"    
// };
axios.defaults.headers.post['Content-Type'] = 'application/json';
axios.defaults.headers.post['Accept'] = 'application/json';
axios.defaults.headers.post['appkey'] = 'e814dc0d-b4b1-4c15-ae72-a749413b1d93';

axios.defaults.timeout = 50000;
let cancel,
    promiseArr = {};
const CancelToken = axios.CancelToken;
//请求拦截器
axios.interceptors.request.use(
    config => {
        //发起请求时，取消掉当前正在进行的相同请求

        // if (promiseArr[config.url]) {
        //     promiseArr[config.url]('操作取消')
        //     promiseArr[config.url] = cancel
        // } else {
        //     promiseArr[config.url] = cancel
        // }
        // store.dispatch("SetLoading", true);
        if (config.headers['Content-Type'] == 'multipart/form-data') {
        } else {
            let _data = config.data || {};
            // let _store = store.state;
            // config.data = qs.stringify(Object.assign(_data, {
            //     appkey: '89a3d5de-c0e2-4458-9539-49a69cb24bc8',
            // }));
           // config.headers['appkey'] == 'application/json'
        }
        return config;

    },
    error => {
        setTimeout(function () {
            //store.dispatch("SetLoading", 0);
        }, 300);
        return Promise.reject(error);
    }
);

//响应拦截器即异常处理
axios.interceptors.response.use(
    response => {
        // store.dispatch('SetLoading', false);
        if (response.data.error_code != 0) {
            if (response.data.error_code != 1000) {
                if(typeof response.data.msg != 'undefined') {
                }
                if (response.data.error_code == 203) {
                    // store.dispatch('clearLoginInfo').then(res => {
                    //     setTimeout(() => {
                    //         router.replace({path: '/login'});
                    //     }, 800);
                    // });
                }
                if (response.data.error_code == 301) {
                    router.replace({path: '/my/verified'});
                }
                if (response.data.error_code == 500) {
                    router.replace({path: '/maintain'});
                    sessionStorage.setItem("tip", response.data.data);
                }

            }
        }
        return response;
    },
    err => {
       // store.dispatch('SetLoading', false)
        if (err && err.response) {
            switch (err.response.status) {
                case 400:
                    err.message = "错误请求";
                    break;
                case 401:
                    err.message = "未授权，请重新登录";
                    break;
                case 403:
                    err.message = "拒绝访问";
                    break;
                case 404:
                    err.message = "请求错误,未找到该资源";
                    break;
                case 405:
                    err.message = "请求方法未允许";
                    break;
                case 408:
                    err.message = "请求超时";
                    break;
                case 500:
                    err.message = "服务器未响应，请尝试刷新";
                    break;
                case 501:
                    err.message = "网络未实现";
                    break;
                case 502:
                    err.message = "网络错误";
                    break;
                case 503:
                    err.message = "服务不可用";
                    break;
                case 504:
                    err.message = "网络超时";
                    break;
                case 505:
                    err.message = "http版本不支持该请求";
                    break;
                default:
                    err.message = `连接错误${err.response.status}`;
            }
        } else {
            err.message = "连接到服务器失败";
        }

        let error_code = err.response.data.error_code;
        if (error_code == 203 || error_code == 205) {
            // store.dispatch('clearLoginInfo').then(res => {
            //     setTimeout(() => {
            //         router.replace({ path: '/login' });
            //     }, 800);
            // });
        }
        if (error_code == 301) {
            router.replace({ path: '/my/verified' });
        }
        if (error_code == 500) {
            router.replace({path: '/maintain'});
            sessionStorage.setItem("tip", response.data.data);
        }
        return Promise.resolve(err.response);
    }
);

export default {
    api,
    //get请求
    get(url, param = {}) {
        return new Promise((resolve, reject) => {
            axios({
                method: "get",
                url,
                params: param,
                cancelToken: new CancelToken(c => {
                    cancel = c;
                })
            }).then(res => {
                resolve(res);
            });
        });
    },
    //post请求
    post(url, param = {}) {
        // console.log(url)
        // console.log(param)
        return new Promise((resolve, reject) => {
            axios({
                method: "post",
                url,
                data: param,
                cancelToken: new CancelToken(c => {
                    cancel = c;
                })
            }).then(res => {
                resolve(res.data);
            });
        });
    },
    //post file请求
    upFile(url, fd) {
        return new Promise((resolve, reject) => {
            axios
                .post(url, fd, { headers: { "Content-Type": "multipart/form-data" } })
                .then(res => {
                    resolve(res);
                });
        });
    }
};
import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import ElementUI from 'element-ui';

import './assets/icon/iconfont.css'
import 'element-ui/lib/theme-chalk/index.css'
import '@/assets/iconfont.css'
import '@/assets/css/style.css'
import 'lib-flexible'

import BaiduMap from 'vue-baidu-map'



Vue.config.productionTip = false;
Vue.use(ElementUI)
Vue.use(BaiduMap, {
  // ak 是在百度地图开发者平台申请的密钥 详见 http://lbsyun.baidu.com/apiconsole/key */
  ak: 'lm2s8Dn9bqm7rs9HuGXwCCUFKeicDjMT'
})

Vue.component('footer-copyright', {
  template: '<p class="footer-msg">©CopyRight 2020-202021 青岛易联万家科技有限公司 版权所有 <a href="www.shequchina.cn" target="_blank">鲁ICP备16006140号</a></p>'
});


import Tools from './tools/tools.js';
Vue.prototype.tools = Tools;

import jsencrypt from './tools/jsencrypt.min.js';
Vue.prototype.$jsencrypt = jsencrypt;

import VideoPlayer from 'vue-video-player'
require('video.js/dist/video-js.css')
require('vue-video-player/src/custom-theme.css')
import 'videojs-flash';
Vue.use(VideoPlayer)

// 当天、24小时、7天、本周
var pickerOptions = {
  shortcuts: [{
    text: '最近10分钟',
    onClick(picker) {
      const end = new Date().getTime();
      const start = new Date().getTime()-10*60*1000
      // 时 分 秒 毫秒
      picker.$emit('pick', [start, end]);
    }
  },{
    text: '最近30分钟',
    onClick(picker) {
      const end = new Date().getTime();
      const start = new Date().getTime()-30*60*1000
      // 时 分 秒 毫秒
      picker.$emit('pick', [start, end]);
    }
  },{
    text: '最近1小时',
    onClick(picker) {
      const end = new Date().getTime();
      const start = new Date().getTime()-1*60*60*1000
      // 时 分 秒 毫秒
      picker.$emit('pick', [start, end]);
    }
  },{
    text: '最近6小时',
    onClick(picker) {
      const end = new Date().getTime();
      const start = new Date().getTime()-6*60*60*1000
      // 时 分 秒 毫秒
      picker.$emit('pick', [start, end]);
    }
  },{
    text: '最近12小时',
    onClick(picker) {
      const end = new Date().getTime();
      const start = new Date().getTime()-12*60*60*1000
      // 时 分 秒 毫秒
      picker.$emit('pick', [start, end]);
    }
  },{
    text: '最近24小时',
    onClick(picker) {
      const end = new Date().getTime();
      const start = new Date().getTime()-24*60*60*1000
      // 时 分 秒 毫秒
      picker.$emit('pick', [start, end]);
    }
  },{
    text: '最近3天',
    onClick(picker) {
      const end = new Date().getTime();
      const start = new Date().getTime()-3*24*60*60*1000
      // 时 分 秒 毫秒
      picker.$emit('pick', [start, end]);
    }
  },{
    text: '最近7天',
    onClick(picker) {
      const end = new Date().getTime();
      const start = new Date().getTime()-7*24*60*60*1000
      // 时 分 秒 毫秒
      picker.$emit('pick', [start, end]);
    }
  },{
    text: '最近15天',
    onClick(picker) {
      const end = new Date().getTime();
      const start = new Date().getTime()-15*24*60*60*1000
      // 时 分 秒 毫秒
      picker.$emit('pick', [start, end]);
    }
  },
  {
    text: '最近30天',
    onClick(picker) {
      const end = new Date().getTime();
      const start = new Date().getTime()-30*24*60*60*1000
      // 时 分 秒 毫秒
      picker.$emit('pick', [start, end]);
    }
  },
  {
    text: '最近2个月',
    onClick(picker) {
      const end = new Date().getTime();
      const start = new Date().getTime()-2*30*24*60*60*1000
      // 时 分 秒 毫秒
      picker.$emit('pick', [start, end]);
    }
  },
  {
    text: '最近3个月',
    onClick(picker) {
      const end = new Date().getTime();
      const start = new Date().getTime()-3*30*24*60*60*1000
      // 时 分 秒 毫秒
      picker.$emit('pick', [start, end]);
    }
  },
  {
    text: '最近6个月',
    onClick(picker) {
      const end = new Date().getTime();
      const start = new Date().getTime()-6*30*24*60*60*1000
      // 时 分 秒 毫秒
      picker.$emit('pick', [start, end]);
    }
  },{
    text: '最近12个月',
    onClick(picker) {
      const end = new Date().getTime();
      const start = new Date().getTime()-12*30*24*60*60*1000
      // 时 分 秒 毫秒
      picker.$emit('pick', [start, end]);
    }
  }
]
}
Vue.prototype.pickerOptions = pickerOptions;


// api
// var httpUrl = 'http://www.sdkjiot.com:8080'
var httpUrl='https://www.sdkjiot.com:443'
// var appKey = '?appKey=e814dc0d-b4b1-4c15-ae72-a749413b1d93'
var appKey = '?appKey='+localStorage.getItem('appKey')
Vue.prototype.httpUrl = httpUrl;
Vue.prototype.appKey = appKey;

// 删除操作
import DelData from './tools/delData.js';
Vue.prototype.delData = DelData;

// 导出表格--开始
import exportExcel from './tools/exportExcel.js';
Vue.prototype.$exportExcel = exportExcel;


var global = [];
Vue.prototype.global = global;


import promission from './promission.js';
Vue.prototype.dynamicRouter = promission;

import '@/global'


import QRCode from 'qrcodejs2';
Vue.prototype.$qrCode = QRCode;

Vue.filter('formatDateTime', function (value) {
  if (!value) return ''
  let date = new Date(value);
  let y = date.getFullYear() + '/';
  let mon = (date.getMonth() + 1) + '/';
  let d = date.getDate();
  return y + mon + d;
});

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount("#app");
// initial state
// shape: [{ id, quantity }]
const state = () => ({
  currentIndex: '/comprehensive',
  menus: [],
  menuPath: '',
  name:''
})

// getters
const getters = {
  menuPath: (state, getters, rootState) => {
    return state.menuPath;
  }
}

// actions
const actions = {
  addMenus({//左侧导航
    state,
    commit
  }, menu) {
    if (menu != null) {
      const m = state.menus.find(item => item.id === menu.id)
      if (!m) {
        menu.check = true;
        commit('addMenus', {
          menu: menu
        });
      } else {
        commit('checkMenu', {
          id: menu.id
        });
      }
    }
  },
  changeIndex({//顶部导航
    state,
    commit
  }, index) {
    if (index != '') {
      if (state.currentIndex != index) {//切换顶部导航后，页面的index
        commit('changeIndex', {
          index: index
        });
      }
    }
  },
  subMenus({//页面上部标签
    state,
    commit
  }, menu) {
    commit('subMenus', {
      menu: menu
    });
  }
}

// mutations
const mutations = {
  addMenus(state, {
    menu
  }) {
    state.menus.push(menu);
  },
  checkMenu(state, {
    id
  }) {
    state.menus.forEach(element => {
      if (element.id == id) {
        element.check = true;
      } else {
        element.check = false;
      }
    });
  },
  subMenus(state, {
    menu
  }) {
    // console.log(menu);
    for (let index = 0; index < state.menus.length; index++) {
      const inMenu = state.menus[index];
      if (inMenu.id == menu.id) {
        if (inMenu.check) {
          if (index == 0) {
            state.menus[index + 1].check = true;
            state.menuPath = state.menus[index + 1].path;
          } else {
            state.menus[index - 1].check = true;
            state.menuPath = state.menus[index - 1].path;
          }

        } else {
          state.menuPath = '';
        }
        state.menus.splice(index, 1);
      }
    }
  },
  changeIndex(state, {
    index
  }) {
    state.currentIndex = index;
    state.menus = [];
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
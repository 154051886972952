<template>
  <!--左侧导航-->
  <!-- <aside :class="{showSidebar:!collapsed}"> -->
  <div class="left-tree">
    <!--展开折叠开关-->
    <!-- <div class="menu-toggle" @click.prevent="collapse">
      <i class="iconfont icon-outdent" v-show="!collapsed" title="收起"></i>
      <i class="iconfont icon-indent" v-show="collapsed" title="展开"></i>
    </div> -->
    <div class="left_title" >
      {{leftTitle}}
    </div>
    <!-- <div style="    max-height: 92%;    overflow-y: auto;"> -->
      <el-tree :data="data" :props="defaultProps" :highlight-current="true" :accordion="true"
        style="width:200px;background: #48AFFF;color:#ffffff" :default-checked-keys="defaultExpandKeys"
        :default-expanded-keys="defaultExpandKeys" ref="tree" node-key="id" @node-click="handleNodeClick">
      </el-tree>
    <!-- </div> -->

  </div>
  <!--导航菜单-->
  <!-- <el-menu :default-active="$route.path" router :collapse="collapsed" ref="leftNavigation">
      <template v-for="(issue) in $router.options.routes"> -->
  <!-- 注意：这里就是leftNavState状态作用之处，当该值与router的根路由的name相等时加载相应菜单组 -->
  <!-- <template v-if="issue.name === $store.state.leftNavState">
          <div v-for="(item,index) in issue.children" :key="index">
            <el-submenu v-if="!item.leaf" :index="index+''" v-show="item.menuShow">
              <template slot="title"><i :class="item.iconCls"></i><span slot="title">{{item.name}}</span></template>
              <el-menu-item v-for="term in item.children" :key="term.path" :index="term.path" 
                :class="$route.path===term.path?'is-active':''">
                <i :class="term.iconCls" v-if="term.menuShow" ></i><span slot="title">{{term.name}}</span>
              </el-menu-item>
            </el-submenu>
            <el-menu-item v-else-if="item.leaf" :index="item.path" :class="$route.path===item.path?'is-active':''"
              v-show="item.menuShow">
              <i :class="item.iconCls"></i><span slot="title">{{item.name}}</span>
            </el-menu-item>
          </div>
        </template>
      </template>
    </el-menu> -->
  <!-- </aside> -->
</template>
<script>
  import {
    mapGetters,
    mapState
  } from 'vuex';
  export default {
    name: 'leftNav',
    data() {
      return {
        loading: false,
        collapsed: this.$store.state.collapsed,
        data: [],
        leftTitle: '',
        defaultProps: {
          children: 'children',
          label: 'name'
        },
        defaultExpandKeys: []
      }
    },
    computed: {
      ...mapState({
        menus: state => state.menus.menus
      }),
      ...mapGetters('menus', {
        products: 'cartProducts',
        total: 'cartTotalPrice'
      })
    },
    methods: {
      //折叠导航栏
      collapse: function () {
        this.collapsed = !this.collapsed;
        this.$store.state.collapsed = this.collapsed;
      },
      handleNodeClick(data, node) {
        if (!node.isLeaf) {
          if (node.expanded) {
            if (data.select < data.children.length) {
              this.addTopNav(data.children[data.select]);
              this.$router.push(data.children[data.select].path);
            } else {
              this.addTopNav(data.children[0]);
              this.$router.push(data.children[0].path);
            }
          }
        } else {
          this.addTopNav(data);
          this.$router.push(data.path);
        }


      },
      //将菜单添加到store中的顶部menus中
      addTopNav(node) {
        console.log('node',node)
        if (node.isTopNav) {
          let routers = this.$router.options.routes; // 获取路由对象
          this.$store.dispatch('menus/addMenus', node);
        }
      },
      // 左侧导航栏根据当前路径默认打开子菜单（如果当前是二级菜单，则父级子菜单默认打开）
      defaultLeftNavOpened() {
        let cur_path = this.$route.path; //获取当前路由;
        let routers = this.$router.options.routes; // 获取路由对象

        let subMenuIndex = '',
          needOpenSubmenu = false;
        for (let i = 0; i < routers.length; i++) {
          let children = routers[i].children;
          if (children) {
            for (let j = 0; j < children.length; j++) {
              if (children[j].path === cur_path) {
                subMenuIndex = children[j].id;
                this.addTopNav(children[j]);
                break;
              }
              // 如果该菜单下还有子菜单
              if (children[j].children && !children[j].leaf) {
                let grandChildren = children[j].children;
                for (let z = 0; z < grandChildren.length; z++) {
                  if (grandChildren[z].path === cur_path) {
                    subMenuIndex = grandChildren[z].id;
                    this.addTopNav(grandChildren[z]);
                    break;
                  }
                }
              }
            }
          }
        }
        // if (this.$refs['leftNavigation'] && needOpenSubmenu) {;
        this.$nextTick(function () {
          if (this.$refs['tree']) {
            // this.$refs['leftNavigation'].open(subMenuIndex); // 打开子菜单
            this.$refs['tree'].setCurrentKey(subMenuIndex);
            this.defaultExpandKeys = [];
            this.defaultExpandKeys.push(subMenuIndex);
          }
        });
      },
      getTreeData() {
        console.log(this.$store.state.leftNavState)
        for (var i = 0; i < this.$router.options.routes.length; i++) {
          var issue = this.$router.options.routes[i];
          if (issue.name === this.$store.state.leftNavState) {
            // console.log(issue)
            this.leftTitle = issue.label;
            this.data = issue.children;
          }
        }
      }
    },
    watch: {
      '$route': function (to, from) { // 路由改变时执行
        if (from) {
          var toPath = to.path;
          var fromPath = from.path;
          var toIndex = toPath.indexOf("/");
          var fromIndex = fromPath.indexOf("/");
          if (toPath.substr(toIndex, toPath.indexOf("/", toIndex + 1)) != fromPath.substr(fromIndex, fromPath.indexOf(
              "/", fromIndex + 1))) {
            this.getTreeData();
          }
        }
        this.defaultLeftNavOpened();
      }
    },
    mounted() {
      for (var i = 0; i < this.$router.options.routes.length; i++) {
        var issue = this.$router.options.routes[i];
        if (issue.name === this.$store.state.leftNavState) {
          this.leftTitle = issue.label;
          this.data = issue.children;
        }
      }
      this.defaultLeftNavOpened();
    },
  }
</script>
<style>
  .collapse-title {
    flex: 1 0 90%;
    order: 1;
  }

  .el-collapse-item__header {
    flex: 1 0 auto;
    order: -1;
  }

  .el-tree-node.is-current>.el-tree-node__content {
    background-color: transparent !important;
    color: #0CFCFF;
  }

  .el-checkbox__input.is-checked+.el-checkbox__label {
    color: black;
  }

  .el-tree-node__content:hover {
    background-color: transparent !important;
  }

  .left-tree {
    /* background-image: url('../../assets/images/left/left.png'); */
    background-repeat: no-repeat;
    background-size: 100% 100%;
    /* background-color: rosybrown; */
  }

  .left_title {
    width: 100%;
    height: 48px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    font-size: 16px;
    padding-left: 22px;
    /* color: #fff; */
    color: red;
  }

  .el-tree-node__expand-icon {
    color: #999999 !important;
  }

  .el-tree-node__expand-icon.is-leaf {
    color: transparent !important;
  }

  .el-tree-node:focus>.el-tree-node__content {
    background-color: transparent !important;
  }
</style>
<style lang="less" scoped>

</style>
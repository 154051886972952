import router from './router'
// const _import = require('./router/_import_' + process.env.NODE_ENV)//获取组件的方法
// const _import = require('./router/data.js') //获取组件的方法
const _import = require('./router/_import_' + process.env.NODE_ENV) //获取组件的方法
import Home2 from "./views/Home2.vue"; //Layout 是架构组件，不在后台返回，在文件里单独引入
import TopNav from './components/nav/topNav.vue'
import defaultLayout from '@/views/default.vue'
import store from "./store";
// import menu from '@/router/menu.js'
// import data from './router/data.js';
import routeDemo from './router/pagesRouterDemo.js'

var getRouter //用来获取后台拿到的路由
console.log(getRouter)
router.beforeEach((to, from, next) => { //刷新判断
    if (!getRouter) { //不加这个判断，路由会陷入死循环
        getRouter = JSON.parse(window.localStorage.getItem('routerPath')); //后台拿到路由
        // console.log(getRouter)
        if (to.path.startsWith('/login')) {
            window.localStorage.removeItem('access-user')
            next()
        } else {
            let user = JSON.parse(window.localStorage.getItem('userInfo'))
            if (!user) {
                next({
                    path: '/login'
                })
                return
            } else {
                next()
            }
        }
        // 动态路由页面的刷新事件
        if (window.localStorage.getItem('routerPath')) {
            console.log("刷新")
            // getRouter = getObjArr('routerPath')
            // getRouter = JSON.parse(window.localStorage.getItem('routerPath')); //后台拿到路由
            // console.log(getRouter)
            routerGo(to, next) //执行路由跳转方法
        }
    } else {
        next()
    }
})

//初始化路由
function initRouter() {
    console.log("此处是初始化")
    var pathRoute = JSON.parse(window.localStorage.getItem('routerPath'));
    // console.log(pathRoute)
    router.beforeEach((to, from, next) => {
        console.log("getRouter", getRouter)
        if (!getRouter) { //不加这个判断，路由会陷入死循环
            console.log('初始化if')
            getRouter = pathRoute //后台拿到路由
            // getRouter = getData()//后台拿到路由
            // saveObjArr('routerPath', getRouter) //存储路由到localStorage
        } else {
            next()
        }
    })
}

// router.beforeEach((to, from, next) => {
//   if (!getRouter) {//不加这个判断，路由会陷入死循环
//     // if (!getObjArr('router')) {
//       // axios.get('https://www.easy-mock.com/mock/5a5da330d9b48c260cb42ca8/example/antrouter').then(res => {
//       getRouter = menu.data.router//后台拿到路由
//       saveObjArr('router', getRouter) //存储路由到localStorage
//       // console.log(getRouter)
//       routerGo(to, next)//执行路由跳转方法
//   } else {
//     next()
//   }

// })


function routerGo(to, next) {
    getRouter = filterAsyncRouter(getRouter) //过滤路由
    // if (getRouter[0].path == '/') {
    //     getRouter[0].path = '';
    // }
    for (var i = 0; i < getRouter.length; i++) {
        var ch = getRouter[i].children
        if (ch.length == 1 && ch[0].visibility == false) {} else if (ch.length == 0) {
            // if (getRouter[i].path == '/') {
            //     getRouter[i].path = '/' + getRouter[i].type;
            // }
        } else {
            console.log(getRouter[i].path)
            getRouter[i].path = '/';
            break
        }
    }
    console.log(getRouter)
    // router.matcher = createRouter().matcher // reset router
    // router.addRoutes(router.options.routes.concat(getRouter))
    // router.addRoutes(getRouter)
    router.addRoutes(getRouter);
    // console.log(router.options.routes)
    // router.options.routes = 
    router.options.routes = router.options.routes.concat(getRouter);
    // console.log(router)
    // global.antRouter = getRouter //将路由数据传递给全局变量，做侧边栏菜单渲染工作
    next({
        ...to,
        replace: true
    })
}

function saveObjArr(name, data) { //localStorage 存储数组对象的方法
    localStorage.setItem(name, JSON.stringify(data))
}

function getObjArr(name) { //localStorage 获取数组对象的方法
    return JSON.parse(window.localStorage.getItem(name));
}

function filterAsyncRouter(asyncRouterMap) { //遍历后台传来的路由字符串，转换为组件对象
    const accessedRouters = asyncRouterMap.filter(route => {
        route.meta = {
            keepAlive: true
        }
        // route.visibility = true
        if (route.visibility == false) {
            return
        }

        // meta: { keepAlive: true // 需要被缓存 }
        // console.log(route)
        // if (route.navImg) {
        //     // route.navImg = require(`@/assets/images/navigation/${route.navImg}`)
        //     route.navImg = require(`@/assets/images/navIcon/${route.navImg}`)
        //     // console.log(route.navImg)
        // }
        // if (route.navHovImg) {
        //     route.navHovImg = require(`@/assets/images/navIcon/${route.navHovImg}`);
        //     // console.log(route.navHovImg)

        // }
        if (route.component) { //处理引用的组件
            // if(route.children && route.children.length) {
            // }
            if (route.component === 'Home2') { //Home2组件特殊处理
                route.component = Home2
            } else if (route.component === 'custom') {
                // route.component =  _import(route.path)
                route.component = resolve => require([`@/views${route.path}`], resolve)
            } else {
                // console.log(1111)
                route.component = _import(route.path)
                // route.component = resolve => require([`@/views/${item.path}`],resolve)
            }
        }
        if (route.components) {
            if (route.components.top === 'TopNav') {
                route.components.top = TopNav;
            }
            if (route.components.default === 'defaultLayout') {
                route.components.default = defaultLayout;
            } else {
                route.components.default = _import(route.path)
            }

            // default: () => import('@/views/comprehensive/mainPage.vue'),
        }
        if (route.children && route.children.length) {
            route.children = filterAsyncRouter(route.children)
        }
        // if (route.children){
        //     if(route.children.length) {
        //         // route.children = filterAsyncRouter(route.children)
        //     }else{
        //         route.children = getChildren(route)
        //         // console.log(route.children)
        //         // route.children = filterAsyncRouter(route.children)
        //     }
        // }

        if (route.redirect) {
            var children = route.children;
            if (children && children.length > 0) {
                // route.redirect = '';
                // menuShow
                for (var i = 0; i < children.length; i++) {
                    if (children[i].menuShow == true && children[i].visibility == true) {
                        route.redirect = children[i].path;
                        // console.log(route)
                        break
                    }
                }

            }
        }
        return true
    })

    return accessedRouters
}

// function getChildren(node) {
//     // console.log(node)
//     // console.log(routeDemo)
//     var demo = routeDemo.data
//     for (var i = 0; i < demo.length; i++) {

//         if (node.id == demo[i].id) {
//             return routeDemo[i].children
//         }
//         if (demo[i].children) {
//             let twoMenu = demo[i].children;
//             for (var j = 0; j < twoMenu.length; j++) {

//                 if (node.id == twoMenu[j].id) {
//                     return twoMenu[j].children
//                 }
//                 if (twoMenu[j].children) {
//                     let treeMenu = twoMenu[j].children;
//                     for (var s = 0; s < treeMenu.length; s++) {
//                         if (node.id == treeMenu[s].id) {
//                             // console.log(treeMenu[s])
//                             return treeMenu[s].children
//                         }
//                     }
//                 }
//             }

//         }
//     }

// }

export default { //导出初始化路由的方法跟过滤路由的方法
    initRouter,
    filterAsyncRouter
}
<template>
  <el-row class="container">
    <!--头部-->
    <el-col :span="24" class="topbar-wrap ">
      <div class="flex flex_column">
        <div class="flex justify_between">
          <div>
            <div class="topbar-logo topbar-btn">
              <!-- <a href="/"><img src="../../assets/logo.png" style="padding-left:8px;"></a> -->
              <router-link to="/"><img src="../../assets/images/navigation/h_logo_hover2.png" class=""></router-link>
              <!-- <router-link to="/"><img src="" class=""></router-link> -->
              <span class="titleSD">山东矿机智能物联网云平台</span>
              <!-- <router-link to="/"><img :src="companyLogo" class="logo"></router-link> -->
            </div>
            <div class="topbar-logos">
              <!-- <a href="/"><span v-show="!$store.state.collapsed">Thinglinks 智慧管廊综合管理平台</span></a> -->
              <router-link to="/"><span v-show="!$store.state.collapsed"></span></router-link>
            </div>
          </div>
          <div class="topbar-account topbar-btn fn_size14">
            <el-popover placement="left" title="" width="400" trigger="hover" >
              <div class="color_000000 output">
                <!-- <div class="height_30">姓名：{{user.userName}}</div>
                <div class="height_30">
                  用户账号：{{user.userAccount}}</div>
                <div class="height_30">
                  手机：{{user.phone}}</div>
                <div class="height_30">
                  邮箱：{{user.email}}</div> -->
                 
                  <div v-if="warnData.length>0">
                    <div v-for="(item,index) in warnData" :key="index" class="warnBoard">
                      <div class="warnTitle">
                        <div style="float:left;">{{item.device}}</div>
                        <div style="float:right;"><el-button v-if="item.shwoButton" @click="submit(item)" size="mini">确定</el-button></div>
                        <div style="clear:both;"></div>
                        </div>
                      <div class="warnContent">{{item.content}}</div>
                    </div>
                 </div>
                 <div v-else class="noneData">暂无数据</div>
              </div>
              <div slot="reference" class="flex justify_end align_center"> 
                <div class="flex">
                  <img style="width:25px;height:25px;margin-top: 8px" src="../../assets/images/login/lingdang.png" alt="">
                  <div>消息</div>
                  <div class="num">{{this.warnNum}}</div>
                </div>
              </div>
            </el-popover>
            <el-popover placement="bottom" title="" width="200" trigger="hover">
              <div class="color_000000 ">
                <div class="height_30">姓名：{{user.userName}}</div>
                <div class="height_30">
                  用户账号：{{user.userAccount}}</div>
                <div class="height_30">
                  手机：{{user.phone}}</div>
                <div class="height_30">
                  邮箱：{{user.email}}</div>
                  <div class="flex justify_center mg_top_10">
                    <el-button type="primary" size="small" @click="editPassword">修改密码</el-button>
                </div>
              </div>
              <div slot="reference" class="flex justify_end align_center"> 
                <img style="width:44px;height:44px;margin-right: 12px;" src="../../assets/images/navigation/pho.png">
                <div class="flex flex_column align_start justify_between mg_left_10"
                  style="margin-right: 40px; line-height: 26px;">
                  <div>{{user.userName}}</div>
                  <div></div>
                </div>
              </div>
            </el-popover>
            <img src="../../assets/images/navigation/logout.png" alt="" @click="logout">
          </div>

        </div>



        <div class="topbar-title">
          <!-- 注意：这里就是topNavState作用之处，根据当前路由所在根路由的type值判断显示不同顶部导航菜单 -->
          <el-row>
            <!-- v-show="$store.state.topNavState==='home'" -->
            <el-col :span="24" class="flex">
              <!-- 注：default-active的值来绑定当前选中的菜单 -->
              <el-menu :default-active="defaultActiveIndex" class="el-menu-demo" mode="horizontal"
                @select="handleSelect" :router="true">
                <!-- menu-trigger="click" -->
                <template v-for="item in menuArr">
                  <el-submenu v-if="item.children.length==1&&item.children[0].visibility" :index="item.path">
                    <template slot="title">
                      <!-- 导航的小图标 hoverimg白底 -->
                      <div class="flex justify_center align_center">
                        <!-- <img :src="item.navImg" class="navImg" alt="" style="margin-right: 10px;">
                        <img :src="item.navImg_hover" class="navImg_hover" alt="" style="margin-right: 10px;"> -->
                        <img :src="item.navImg_hover" class="navImg" alt="" style="margin-right: 10px;">
                        <!-- <i :class="item.iconCls"></i> -->
                        <span>{{item.label}}</span>
                      </div>
                    </template>
                    <template v-if="item.children" v-for="twoitem in item.children">
                      <!-- 二级 -->
                      <el-menu-item v-if="!twoitem.children  && twoitem.menuShow&&twoitem.visibility" :index="twoitem.path">{{twoitem.name}}</el-menu-item>
                      <el-submenu :index="twoitem.path" v-if="twoitem.children&& twoitem.menuShow&&twoitem.visibility">
                        <template slot="title">{{twoitem.name}}</template>
                        <!-- 三级 -->
                        <el-menu-item v-for="threeitem in twoitem.children" :key="threeitem.id" :index="threeitem.path" v-if="threeitem.visibility">
                          {{threeitem.name}}
                        </el-menu-item>
                      </el-submenu>
                    </template>
                  </el-submenu>

                  <!-- 如果此菜单下有子菜单的话 -->
                  <el-submenu v-if="item.children.length !=1&&item.visibility" :index="item.path">
                    <template slot="title">
                      <img :src="item.navImg_hover" class="navImg" alt="" style="margin-right: 10px;">
                      <span>{{item.label}}</span>
                    </template>
                    <template v-if="item.children" v-for="twoitem in item.children">
                      <!-- 二级 -->
                      <el-menu-item v-if="!twoitem.children  && twoitem.menuShow&&twoitem.visibility" :index="twoitem.path">{{twoitem.name}}</el-menu-item>

                      <el-submenu :index="twoitem.path" v-if="twoitem.children&& twoitem.menuShow&&twoitem.visibility">
                        <template slot="title">{{twoitem.name}}</template>
                        <!-- 三级 -->
                        <el-menu-item v-for="threeitem in twoitem.children" :key="threeitem.id" :index="threeitem.path" v-if="threeitem.visibility">
                          {{threeitem.name}}
                        </el-menu-item>
                      </el-submenu>

                    </template>
                  </el-submenu>
                </template>
              </el-menu>
              <el-menu ref="blank_menu"  :default-active="activeIndex" class="el-menu-demo" mode="horizontal" @select="handleSelectBlank"  >
                <el-submenu index="2" v-if="this.cameraButton">
                  <template slot="title">
                    <a  class="aBlank mg_left_8" style="color:white" href="http://47.104.99.213:8080/Thingworx/Mashups/TW.Analytics.ConsoleMashup?appKey=23d7ee74-563a-4407-ba7d-24449064040e&x-thingworx-session=true" target="_blank">预测性维护</a>
                  </template>
                </el-submenu>
                <el-submenu index="3" v-if="this.machineLearning">
                  <template slot="title">
                    <a  class="aBlank mg_left_8" style="color:white" href="https://www.hik-cloud.com/safe-center/index.html#/login/retail?type=true&direction=%2Fvideo%2Fscene" target="_blank">视频监控</a>
                  </template>
                </el-submenu>
              </el-menu>
            </el-col>

          </el-row>
         
        </div>
      </div>

      <el-dialog :visible.sync="dialogEditPassword" width="30%">
        <div slot="title" class="fn_size18 color_000000  flex font_bold height_40">修改密码</div>
        <el-form :model="ruleForm" status-icon :rules="rules" ref="ruleForm" label-width="100px"
          class="flex flex_column align_center mg_top_10" style="width:100%">
          <el-form-item label="当前密码：" prop="currentPassword">
            <el-input type="password" v-model="ruleForm.currentPassword" autocomplete="off" show-password></el-input>
          </el-form-item>
          <el-form-item label="密码：" prop="newPassword">
            <el-input type="password" v-model="ruleForm.newPassword" autocomplete="off" show-password></el-input>
          </el-form-item>
          <el-form-item label="密码强度">
            <password-strength v-model="ruleForm.newPassword" @child-event="parentEvent" class="width_220" style="padding-top:10px"></password-strength>
          </el-form-item>
          <el-form-item label="确认密码：" prop="confirmNewPassword">
            <el-input type="password" v-model="ruleForm.confirmNewPassword" autocomplete="off" show-password></el-input>
          </el-form-item>
        </el-form>
        <div class="flex justify_center pdb_20">
          <el-button @click="dialogEditPassword=false">取消</el-button>
          <el-button type="primary" @click="submitForm('ruleForm')">提交</el-button>
        </div>
      </el-dialog>
    </el-col>
  </el-row>
</template>
<script>
  import 'element-ui/lib/theme-chalk/display.css';
  import PasswordStrength from "@/components/progress";
  import {
    road
  } from '../../road.js'
  import {
    userConfigChangePassword,
    currentAlarmQuery,
    confirmAlarm,
    
  }from '../../api/api_login.js'
import data from '../../router/data.js';

  export default {
    components:{PasswordStrength},
    data() {
      var validatePass = (rule, value, callback) => {
        console.log(value, 1)
        if (value === '') {
          console.log(value)
          callback(new Error('请输入密码'));
        } else {
          if (this.ruleForm.confirmNewPassword !== '') {
            this.$refs.ruleForm.validateField('confirmNewPassword');
          }
          callback();
        }
      };
      var validatePass2 = (rule, value, callback) => {
        if (value === '') {
          callback(new Error('请再次输入密码'));
        } else if (value !== this.ruleForm.newPassword) {
          callback(new Error('两次输入密码不一致!'));
        } else {
          callback();
        }
      };
      return {
        loading: false,
        // companyName: '',
        nickname: '',
        defaultActiveIndex: '',
        homeMenu: false,
        messageCount: 5,
        menuArr: [],
        companyName: '',
        companyLogo: '',
        user: {},
        logoTimer: null,
        logoTimer1: null,
        dialogEditPassword: false,
        alarmType:'',
        ruleForm: {
          currentPassword: '',
          newPassword: '',
          confirmNewPassword: '',
        },
        rules: {
          newPassword: [{
            validator: validatePass,
            trigger: 'blur'
          }],
          confirmNewPassword: [{
            validator: validatePass2,
            trigger: 'blur'
          }],
          currentPassword: [{
            required: true,
            message: '请输入原始密码',
            trigger: 'blur'
          }]
        },
        passwordType: false,
        activeIndex: null,
        cameraButton:'',
        machineLearning:'',
        strength:'',
        warnData:[],
        warnNum:0,
        
      }
    },
    created() {
      road.$on('setNickName', (text) => {
        this.nickname = text;
      });

      road.$on('goto', (url) => {
        if (url === "/login") {
          localStorage.removeItem('access-user');
          this.$router.push(url);
        }
      });
      // 组件创建完后获取数据
      this.fetchNavData();      
    },
    methods: {
   
      parentEvent(data){
        this.strength = data
      },
      // 修改密码
      editPassword() {
        // this.ruleForm = {};
        this.ruleForm = {
            currentPassword: '',
            newPassword: '',
            confirmNewPassword: '',
          },
          this.dialogEditPassword = true
      },
      submitForm(formName) {
        this.$refs[formName].validate((valid) => {
          if (valid) {
            // alert('submit!');
            console.log(this.strength);
            if(this.strength=='弱'){
              
              this.$message({
                type:'warning',
                message:'密码强度弱，请重新输入！'
              })
            }else{
              this.userConfigChangePassword()
            }
          } else {
            // console.log('error submit!!');
            return false;
          }
        });
      },
      
      // 修改密码接口
      async userConfigChangePassword() {
        // console.log(this.user)
        this.ruleForm.userID = this.user.userID;
        if (this.passwordType) {
          this.ruleForm.type = true;
        }
        const selectRes = await userConfigChangePassword(this.ruleForm);
        if (selectRes.data) {
          if (selectRes.data.result) {
            this.$message({
              message: selectRes.message,
              type: 'success'
            });
            this.dialogEditPassword = false;
            this.ruleForm = {};
            this.passwordType = false;
            // setTimeout(() => {
            //    this.$message({
            //     message: '请重新登录',
            //     type: 'warning'
            //   });
            //   localStorage.removeItem('userInfo');
            //   localStorage.removeItem('appKey');
            //   localStorage.removeItem('routerPath');
            //   localStorage.clear();
            //   road.$emit('goto', '/login');
            // }, 1000)
          } else {
            this.$message.error(selectRes.message);
          }
        } else {
          this.$confirm(selectRes.message, '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
          }).then(() => {
            this.passwordType = true;
            this.userConfigChangePassword();
          }).catch(() => {
            this.$message({
              type: 'info',
              message: '已取消'
            });
          });
        }

      },
      async currentAlarmQuery() {
        var param = {
          userID:this.user.userID
        }
        const selectRes = await currentAlarmQuery(param);
        
        this.warnData = selectRes.array;
        this.warnNum = selectRes.quan;
      },
      jumpTo(url) {
        this.$router.push(url); //用go刷新
      },
      handleSelect(index, indexPath) {
        // console.log(index)
        // console.log("indexPath",indexPath)
        this.defaultActiveIndex = index;
        this.$store.dispatch('menus/changeIndex', index);
      },
      fetchNavData() { // 初始化菜单激活项
        let cur_path = this.$route.path; //获取当前路由
        // console.log("当前路由", cur_path)
        // console.log("当前路由", this.$router.options.routes)
        let routers = this.$router.options.routes; // 获取路由对象
        // console.log(routers)
        let nav_type = "",
          nav_name = "";
        this.menuArr = [];
        for (var i = 0; i < routers.length; i++) { //一级
          if (routers[i].id != undefined) {
            // console.log(routers[i].label)
            this.menuArr.push(routers[i]);
          }
          let children = routers[i].children;
          if (children) { //2级
            // console.log(children)
            for (let j = 0; j < children.length; j++) {
              // console.log(children[j].path)
              if (children[j].path === cur_path) {
                // console.log(children[j])
                nav_type = routers[i].type;
                nav_name = routers[i].name;
                this.defaultActiveIndex = children[j].path;
                break;
              }

              // 如果该菜单下还有子菜单              ---3级
              if (children[j].children) {
                let grandChildren = children[j].children;
                // console.log(grandChildren)
                for (let z = 0; z < grandChildren.length; z++) {
                  if (grandChildren[z].path === cur_path) {
                    // console.log(grandChildren[z])
                    nav_type = routers[i].type;
                    nav_name = routers[i].name;
                    this.defaultActiveIndex = grandChildren[z].path;
                    // console.log(22222)
                    break;
                  }

                  if (grandChildren[z].children) {
                    let lastChildren = grandChildren[z].children;
                    for (let s = 0; s < lastChildren.length; s++) {
                      if (lastChildren[s].path === cur_path) {
                        nav_type = routers[i].type;
                        nav_name = routers[i].name;
                        this.defaultActiveIndex = grandChildren[z].path;
                        break;
                      }
                    }
                  }




                }
              }
            }
          }
        }
        console.log('menuArr',this.menuArr)
        this.$store.state.topNavState = nav_type; //当前顶部导航
        // console.log('当前顶部导航',this.$store.state.topNavState)
        this.$store.state.leftNavState = nav_name; //当前左侧导航
        // console.log('当前左侧导航', this.$store.state.leftNavState)
        // console.log(this.$store.state)//当前顶部导航下，打开的页面
        if (nav_type == "home") {
          this.defaultActiveIndex = "/";
        }
        // else {
        //   this.defaultActiveIndex = "/" + nav_name;
        // }
      },


      logout() {
        //logout
        this.$confirm('确认退出吗?', '提示', {
          confirmButtonClass: 'el-button--warning'
        }).then(() => {
          //确认
          localStorage.removeItem('userInfo');
          localStorage.removeItem('appKey');
          road.$emit('goto', '/login');
        }).catch(() => {});
      },


      watchClick(){
        window.open('https://www.hik-cloud.com/safe-center/index.html#/login/retail?type=true&direction=%2Fvideo%2Fscene', "_blank")
      },
      maintainClick(){
        window.open('http://47.104.99.213:8080/Thingworx/Mashups/TW.Analytics.ConsoleMashup?appKey=23d7ee74-563a-4407-ba7d-24449064040e&x-thingworx-session=true')
      },
      // 跳转页面
      handleSelectBlank() {
        this.$set(this.$refs['blank_menu'], "activeIndex", '');//将选中的菜单取消选中
      },
         //确定问题2021-11-11
      async submit(data){
      this.confirmAlarm(data);
      var param = {
          userID:this.user.userID
        }
        const selectRes = await currentAlarmQuery(param);
        this.warnData = selectRes.array;
        this.warnNum = selectRes.quan;
        this.$message.success("确认成功！")
      },
      //当用户点击确认
      confirmAlarm(data){
        var parmData = {
          ID:data.ID,
          alarmType:data.alarmType
        }
        console.log("参数",parmData);
        const selectRes = confirmAlarm(parmData);
        console.log(selectRes,'确认');
      },
      
      
      
    },
    mounted() {
      
      let userInfo = window.localStorage.getItem('userInfo');
      if (userInfo) {
        this.user = JSON.parse(userInfo);
        this.cameraButton = this.user.cameraButton;
        this.machineLearning = this.user.machineLearning;
      } else {
        localStorage.removeItem('userInfo');
        localStorage.removeItem('companyInfo');
        localStorage.removeItem('appKey');
        road.$emit('goto', '/login');
      }
      //  console.log(JSON.parse(window.localStorage.getItem('userInfo')))
      let company = window.localStorage.getItem('companyInfo');
      let appKey = window.localStorage.getItem('appKey');
      let that = this
      this.logoTimer = setInterval(function () {
        // console.log(company);
        if (company != null) {
          clearInterval(that.logoTimer)
          company = JSON.parse(company);
          // console.log(company)
          that.companyName = company.title || '';
          // this.companyLogo ='http://121.37.181.242:8080'+ company.titlePic +'?appKey=e814dc0d-b4b1-4c15-ae72-a749413b1d93';
          that.companyLogo = 'http://121.37.181.242:8080' + company.titlePic + '?appKey=' + appKey;
           //that.companyLogo = 'https://www.sdkjiot.com:8443/Thingworx/Things/' + company.titlePic + '?appKey=' + appKey;
          // console.log(this.companyLogo)

          // this.nickname = user.Username || '';
          // this.companyName = user.companyName || '';
        }else{
          company = window.localStorage.getItem('companyInfo');
          appKey = window.localStorage.getItem('appKey');
        }
      }, 100);
      this.currentAlarmQuery();
      // 定时器
      this.logoTimer1 = setInterval(
       this.currentAlarmQuery
      , 10000);
    
      // this.confirmAlarm();
    },

    watch: {
      '$route': function (to, from) { // 路由改变时执行
        //console.info("to.path:" + to.path);
        this.fetchNavData();
      }
    }
  }
</script>
<style lang="scss" scoped>
  .titleSD{
    font-size: 30px;
    font-weight: 1000;
    font-family: '仿宋';
    margin-left: 10px;
  }
  .width_btn{
    width: 180px;
  }
  .width_btn1{
    width: 180px;
  }
  ::v-deep .el-submenu__title i{
   color: #FFFFFF!important;
  }
  .el-submenu__icon-arrow{
    right: 10px!important;
  }

  // .main{
  //   padding: 0 20px 0 20px;
  //   box-sizing: border-box;
  // }

  .maintain{
    
    cursor: pointer;
    background-color: rgb(42,55,100);
    color: white;
    text-align: center;
    font-size: 14px;
    box-sizing: border-box;
    position: relative;
    
  }
  .maintain:hover{
    background-color: rgb(0,155,253);
    color: white;
  }
  // .wat{
  //   margin: 0 10px 0 10px;
  //   box-sizing: border-box;
  // }
  .watch{
    cursor: pointer;
    background-color: rgb(42,55,100);
    color: white;
    text-align: center;
    font-size: 14px;
  }
  .watch:hover{
    background-color: rgb(0,155,253);
    color: white;
  }
  .topbar-logos-right {
    float: left;
    height: 76px;
    margin-left: 20px;
    line-height: 76px;
  }

  .el-menu--horizontal>.el-menu-item {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  /* //设置选中el-menu-item时的样式 */
  .el-menu--horizontal>.el-menu-item.is-active {
    /* // border-left: #33A2EF solid 6px !important;
    // background-color: #E2EFF9 !important;
    // background-image: url('../../assets/images/top/menu_select.png'); */
    background-repeat: no-repeat;
    /* border: none !important; */
    background-size: 100% 100%;
    /* color: #02042F !important; */
    background: #48AFFF;
    color: #FFFFFF;
    /* border: 1px solid #48AFFF; */
  }

  .el-menu--horizontal>.el-menu-item:not(.is-disabled):hover,
  .el-menu--horizontal>.el-menu-item:not(.is-disabled):focus,
  .el-menu--horizontal>.el-menu-item.is-active {
    border: none !important;
    color: #FFFFFF;
  }

  /* .el-menu--horizontal>.el-menu-item:not(.is-disabled):hover,
  .el-menu--horizontal>.el-menu-item:not(.is-disabled):focus,
  .el-menu--horizontal>.el-menu-item:hover {
    color: #FFFFFF;
    display: flex;
    justify-content: center;
    align-items: center;
  } */

  /* .el-menu--horizontal>.el-menu-item:not(.is-disabled):hover,
   .el-menu--horizontal>.el-menu-item:not(.is-disabled):focus,
   .el-menu--horizontal>.el-menu-item.is-active .navImg {
     display: none;
  }

   .el-menu--horizontal>.el-menu-item:not(.is-disabled):hover,
   .el-menu--horizontal>.el-menu-item:not(.is-disabled):focus,
   .el-menu--horizontal>.el-menu-item.is-active .navImg_hover {
     display: block;
   } */

  .el-menu--horizontal>.el-menu-item.is-active {
    border-bottom: none;
  }

  .el-menu-item {
    
    /* border: none !important; */
    /* background-size: 100% 100%; */
    font-weight: 500;
    font-size: 14px;
    text-align: center;
  }

  .diy {
    background-color: #E2EFF9 !important;
    color: #38B2FF !important;
    font-size: 80px;
  }

  .el-menu.el-menu--horizontal {
    border-bottom: none;
  }

  .logo {
   position: relative;
    width: 140px;
    height: 30px;
  }
  .container .topbar-wrap .topbar-title{
    padding-right: 20px;
    text-align: right;
    
  }

  .container .topbar-wrap{
    line-height: 50px;
  }
  .aBlank:hover {
    color: #1A1A1A;
    margin-left: 8px;
  }
  .width_220{
    width:220px
  }
  .num{
    width: 30px;
    height: 20px;
    line-height: 20px;
    border-radius: 5px;
    background-color: lightyellow;
    color: black;
  }
  .warnBoard{
    border-bottom: 1px solid gray;
    padding-bottom: 20px;
  }
  .warnContent{
    line-height: 30px;
    font-size: 14px;
  }
  .warnTitle{
    height: 35px;
    line-height: 35px;
    font-size: 16px;
    color: black;
  }
  .output{
  height: 400px; 
  overflow: auto;
  }
  .noneData{
    width: 100%;
    height: 100%;
    line-height: 400px;
    text-align: center;
    font-size: 16px;
  }
</style>
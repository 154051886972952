<template>
  <el-row class="container diy_width">
    <!--头部-->
    <el-col :span="24">
      <router-view name="top"></router-view>
    </el-col>
    <el-col :span="24" class="main">
      <!--左侧导航-->
      <router-view name="aside"></router-view>
      <!--右侧内容区-->
      <section class="content-container">
        <div class="grid-content bg-purple-light">
          <transition name="fade" mode="out-in">
            <router-view></router-view>
          </transition>
        </div>
      </section>
    </el-col>
    <!-- <div style="    height: 100%;    width: 100%;" class="shandong">123</div> -->
  </el-row>
</template>
<script>
  export default {
    name: 'home',
    data() {
      return {
        loading: false,
        notify: null,
        timer: null,
        notifyList: [],
        warnPlanTimer: null,
        warnPlanNotify: null,
        warnPlanList: [],
        warnPlanListIndex: 0,
        warnPlanStepIndex: 0,
      }
    },
    created() {},
    beforeDestroy() {},
    mounted() {
    },
    methods: {}
  }
</script>
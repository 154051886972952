/**
 * 登录
 */
 import req from './index.js'

 
export const login = param => { return req.post('EMS.Thing.Api.Login/Services/Login',param) }; //登录
export const platformSettingTitlePicAndContent = param => { return req.post('EMS.Thing.Api.Setting/Services/PlatformSettingTitlePicAndContent',param) };//

// 修改用户密码
export const userConfigChangePassword = param => { return req.post('EMS.Thing.Api.Permission/Services/UserConfigChangePassword',param) };//

export const currentAlarmQuery = param => { return req.post('EMS.Thing.Api.EquipmentDetails/Services/CurrentAlarmQuery',param) };//小铃铛
//
export const confirmAlarm = param => { return req.post('EMS.Thing.Api.iFLYTEK/Services/ConfirmAlarm',param) };

function delData(dataLength, pageQuantity, currentPage, multipleChoice) {
    var changePAge = 1;
    if (multipleChoice) { //批量删除
        if (pageQuantity == 1) { //当数据只有一页的时候，返回当前页
            changePAge = currentPage
        } else {
            if (multipleChoice == dataLength) { //某一页全部删除时，页面传值-1
                changePAge = pageQuantity - 1;
            } else {
                changePAge = currentPage
            }
        }

    } else { //单个删除
        if (pageQuantity == 1) {//当数据只有一页的时候，返回当前页
            changePAge = currentPage
        } else {
            if (dataLength == 1) { //某一页，删除操作，数据只有一条的时候
                changePAge = pageQuantity - 1;
            } else {
                changePAge = currentPage
            }
        }
    }

    return changePAge;
}

export default {
    delData
}
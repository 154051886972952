import Vue from "vue";
import VueRouter from "vue-router";
import data from './data.js';



Vue.use(VueRouter);
// var constantRouterMap=data

const routes = data;

const router = new VueRouter({
  // mode: "history",
  base: process.env.BASE_URL,
  routes
});
//const supplyCompanyList = r => require.ensure([], () => r(require('../views/systemConfiguration/thirdpartyDeviceAccount.vue')), 'thirdpartyDeviceAccount')
// router.beforeEach((to, from, next) => {
//   // console.log(to.path.startsWith('/login'))
//   if (to.path.startsWith('/login')) {
//     window.localStorage.removeItem('access-user')
//     next()
//   } else {
//     let user = JSON.parse(window.localStorage.getItem('access-user'))
//     // if (!user) {
//     //   next({
//     //     path: '/login'
//     //   })
//     // } else {
//       next()
//     // }
//   }
// });

// const originalPush = VueRouter.prototype.push
// VueRouter.prototype.push = function push(location) {
//   return originalPush.call(this, location).catch(err => err)
// }

export default router;
// export default new VueRouter({
//   routes: constantRouterMap
 
// })